import React, { useState, useEffect } from "react"
import MainContent from "../../components/Layout/MainContent"
import Title from "../../components/Text/Title"
import BtnRounded from "../../components/Buttons/BtnRounded"
import PostFooter from "../../components/News/PostFooter"
import BodyContent from "../../components/Text/BodyContent"
import "./Detail.scss"
import withGuestLayout from "../../hoc/withGuestLayout"
import { useGuestApi } from "../../hooks/useApi"
import {
  getDescription,
  getSingleImage,
  getTitle,
  getLocation,
  getContract,
  getRecruiter,
  getPublishOn,
  getContact,
  getType,
} from "../../helpers/Publications"
import { formatPageInformation } from "../../helpers/Meta"
import badge from "./img/logo-badge.png"
import { useTranslation } from "react-i18next"
import { validateEmail } from "../../helpers/Emails"
import Logo from "../Logo/Logo"
import { logo } from "../../constants/logo"

function VacancyDetail({ slug }) {
  const { getPublicationBySlug, setPageInformation } = useGuestApi()
  const [vacancyItem, setVacancyItem] = useState(null)
  const [contactLink, setContactLink] = useState("")
  const { t } = useTranslation()

  useEffect(
    function fetchPublications() {
      getPublicationBySlug(slug).then(response => {
        setPageInformation(formatPageInformation(response))
        setVacancyItem(response)
      })
    },
    [getPublicationBySlug, setPageInformation, slug]
  )

  useEffect(() => {
    if (vacancyItem?.contact) {
      setContactLink(
        validateEmail(getContact(vacancyItem.contact))
          ? `mailto:${getContact(vacancyItem.contact)}`
          : getContact(vacancyItem.contact)
      )
    }
  }, [vacancyItem])

  return (
    <>
      {vacancyItem && (
        <MainContent image={vacancyItem.image} _className={"vacancy__detail"}>
          <Title>{getTitle(vacancyItem.title)}</Title>
          <div className="vacancy__detail--header">
            <div className="vacancy__location">
              <h3>
                {t("vacanciesDetail.location")}{" "}
                <strong>{getLocation(vacancyItem.location)}</strong>
              </h3>
            </div>
            <div className="vacancy__type">
              <h3>
                {t("vacanciesDetail.contract")}{" "}
                <strong>{getContract(vacancyItem.contract)}</strong>
              </h3>
            </div>
            <div className="vacancy__btn">
              <BtnRounded third>{getType(vacancyItem.type)}</BtnRounded>
            </div>
          </div>
          <div className="vacancy__detail--content">
            <BodyContent html={getDescription(vacancyItem.description)} />
          </div>
          <div className="vacancy__detail--footer">
            <div className="recruiter">
              <div className="recruiter__visual">
                <Logo color={logo.default} />
              </div>
              <div className="recruiter__info">
                <h3>{t("vacanciesDetail.questions")}</h3>
                <p>{t("vacanciesDetail.contactRecruiter")}</p>
                <a href={contactLink}>{getRecruiter(vacancyItem.recruiter)}</a>
              </div>
            </div>
            <div className="vacancy__detail--footer--text">
              <p>
                {t("vacanciesDetail.applyLine1")}{" "}
                <a href={contactLink}>{getContact(vacancyItem.contact)}</a>.
                {t("vacanciesDetail.applyLine2")}
              </p>
            </div>
            <BtnRounded primary large modLink href={contactLink}>
              {t("vacanciesDetail.action")}
            </BtnRounded>
          </div>
          <PostFooter date={getPublishOn(vacancyItem.publish_on)} />
        </MainContent>
      )}
    </>
  )
}

export default withGuestLayout(VacancyDetail, {
  page: "Vacatures",
})
