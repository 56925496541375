import { Link } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import { getTitle } from "../../helpers/Publications"
import Icon from "../Icon/Icon"
import Logo from "../Logo/Logo";
import { logo } from "../../constants/logo"
import "./VacancyItem.scss"

function VacancyItem(props) {
  const { title, slug } = props
  const { t } = useTranslation()

  return (
    <Link to={`/vacatures/${slug}`} className="vacancy">
      <div className="vacancy__inner">
        <Logo className="vacancy__badge" isCompact color={logo.default} />
        <div className="vacancy__content">
          <h3 className="vacancy__title">{getTitle(title)}</h3>
          <span className={"vacancy__link"}>{t("vacancyItem.action")}</span>
        </div>
        <Icon name="chevron-right" className="vacancy__icon" />
      </div>
    </Link>
  )
}

export default VacancyItem
