import React, { useState, useEffect } from "react"
import { useGuestApi } from "../../hooks/useApi"
import VacancyItem from "../Vacancies/VacancyItem"

const initialPagination = {
  total: null,
  skip: 0,
  limit: 10,
}

export default function VacanciesGrid({ history }) {
  const [pagination, setPagination] = useState(initialPagination)
  const [page, setPage] = useState(1)
  const { getVacanciesPublications } = useGuestApi()
  const [vacancies, setVacancies] = useState([])
  useEffect(fetchVacanciesPublications, [page])

  function fetchVacanciesPublications() {
    getVacanciesPublications(pagination).then(response => {
      setPagination({
        total: response.total,
        limit: response.limit,
        skip: response.skip,
      })
      setVacancies(vacancies.concat(response.grid.items))
    })
  }

  return (
    <div className="vacancies__grid">
      {vacancies.map((item, i) => (
        <VacancyItem {...item} key={i} />
      ))}
    </div>
  )
}
