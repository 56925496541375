import React, { useEffect, useState } from "react"
import MainContent from "../../components/Layout/MainContent"
import Title from "../../components/Text/Title"
import VacanciesGrid from "../Grid/VacanciesGrid"
import "./Overview.scss"
import { useGuestApi } from "../../hooks/useApi"
import { getExternalUrl, getSingleImage, getTitle } from "../../helpers/Publications"
import withGuestLayout from "../../hoc/withGuestLayout"
import BodyContent from "../Text/BodyContent"
import { returnLayoutSection } from "../../helpers/Layout"
import { imageUrl } from "../../helpers/ImageUrl"

function VacanciesOverview() {

  const { getVacanciesOverviewPublication } = useGuestApi();
  const [content, setContent] = useState(null);

  useEffect(() => {
    getVacanciesOverviewPublication().then(response => {
      setContent(response);
    })
  }, [getVacanciesOverviewPublication])

  if(!content) {
    return null;
  }

  return (
    <MainContent headerWidth={960} headerHeight={312} {...content}>
      <Title>{getTitle(content.title)}</Title>
      <BodyContent>
        {content.layout
          ? Array.isArray(content.layout.items)
            ? content.layout.items.map((item, i) => {
                return returnLayoutSection(item, i)
              })
            : null
          : null
        }
      </BodyContent>
      <VacanciesGrid />
      <div className="vacancies__footer">
        <img src={ imageUrl(getSingleImage(content?.footerimage), 192, 35)} alt="Mediahuis" />
        <a
          href={getExternalUrl(content?.footerlink)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {getTitle(content?.footertext)}
        </a>
      </div>
    </MainContent>
  )
}

export default withGuestLayout(VacanciesOverview, {
  page: "Vacatures",
})
