import React from "react"
import { Router } from "@reach/router"
import VacanciesOverview from "../components/Vacancies/Overview"
import VacancyDetail from "../components/Vacancies/Detail"

const Vacancies = () => {
  return (
    <Router>
      <VacanciesOverview path="vacatures" />
      <VacancyDetail path="vacatures/:slug" />
    </Router>
  )
}

export default Vacancies
